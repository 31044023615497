import React, { useEffect, useState } from "react";
import app from "./base.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  // NB: current user corresponds to the profiles table in firestore
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  useEffect(() => {
    app.auth().onAuthStateChanged(async (user) => {
      if (user != null) {
        await app
          .firestore()
          .collection("profiles")
          .where("id", "==", user.uid)
          .get()
          .then((snapshot) => {
            setCurrentUser(snapshot.docs[0].data());
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setPending(false);
    });
  }, []);

  if (pending) {
    return <>{loading}</>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
