import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';

const app1 = {
    apiKey: "AIzaSyDGhigfYiz9QH54_oxgCwcFtYvFqaN0Nsw",
    authDomain: "umoja-app.firebaseapp.com",
    databaseURL: "https://umoja-app.firebaseio.com",
    projectId: "umoja-app",
    storageBucket: "umoja-app.appspot.com",
    messagingSenderId: "831522312134",
    appId: "1:831522312134:web:b1283624f5c63d5e11acc7",
    measurementId: "G-TBKG0GMB36"
};
  
var app = firebase.initializeApp(app1);
/*const storage = firebase.storage;

export  {
   storage, app as default
}
 */
export default app;
