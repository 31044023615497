import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Auth";

/*
 * Route that is protected with authentication. Assumes that we have an auth context
 * that contains a current user.
 *
 * adminOnly: is the current route only for admins with access to cms?
 */
const AuthenticatedRoute = ({ adminOnly = false, ...props }) => {
  const { currentUser } = useContext(AuthContext);

  // non admin routes must be authenticated
  // admin routes must have the access cms flag
  if (!adminOnly && !!currentUser) {
    return <Route {...props} />;
  } else if (adminOnly && currentUser?.access_cms) {
    return <Route {...props} />;
  }

  return <Redirect to={"/404"} />;
};

export default AuthenticatedRoute;
