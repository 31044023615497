import React, { Component } from "react";
//import { HashRouter, Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./scss/style.scss";
import { AuthProvider } from "./context/Auth";
import AuthenticatedRoute from "./context/AuthenticatedRoute";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/auth/login/Login"));
const TeamRegistration = React.lazy(() =>
  import("./views/unauth/team/team-registration")
);
const SuccessPage = React.lazy(() =>
  import("./views/unauth/shared/success-page")
);
const PickRegistration = React.lazy(() =>
  import("./views/unauth/shared/pick-registration")
);
const TeamPayment = React.lazy(() =>
  import("./views/unauth/team/team-payment")
);
const PlayerRegistration = React.lazy(() =>
  import("./views/unauth/player/player-registration")
);
const PlayerPayment = React.lazy(() =>
  import("./views/unauth/player/player-payment")
);
const BazaarRegistration = React.lazy(() =>
  import("./views/unauth/bazaar/bazaar-registration")
);
const BazaarPayment = React.lazy(() =>
  import("./views/unauth/bazaar/bazaar-payment")
);
const BazaarSuccessPage = React.lazy(() =>
  import("./views/unauth/bazaar/bazaar-success")
);
const ConflictSubmission = React.lazy(() =>
  import("./views/unauth/conflict/conflict-submission")
);
const ConflictPayment = React.lazy(() =>
  import("./views/unauth/conflict/conflict-payment")
);
const ConflictSubmissionSuccess = React.lazy(() =>
  import("./views/unauth/conflict/conflict-success")
);
const MatchGameSheet = React.lazy(() =>
  import("./views/admin/ecosystem/uGames/matches/2023/MatchGameSheet")
);
const ExportGameSheets = React.lazy(() =>
  import("./views/admin/ecosystem/uGames/matches/2023/ExportGameSheets")
);
const ScheduleStandingsViewer = React.lazy(() =>
  import("./views/admin/ecosystem/uGames/matches/2023/ScheduleStandingsViewer")
);
const Page404 = React.lazy(() => import("./views/auth/page404/Page404"));
const Page500 = React.lazy(() => import("./views/auth/page500/Page500"));

class App extends Component {
  render() {
    return (
      <AuthProvider>
        <Router>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <AuthenticatedRoute
                exact
                path="/registration"
                name="Pick registration"
                render={(props) => <PickRegistration {...props} />}
              />
              <AuthenticatedRoute
                exact
                path="/team-registration"
                name="Team registration"
                render={(props) => <TeamRegistration {...props} />}
              />
              <AuthenticatedRoute
                exact
                path="/team-registration-payment"
                name="Team payment"
                render={(props) => <TeamPayment {...props} />}
              />
              <AuthenticatedRoute
                exact
                path="/registration-success"
                name="Success Page"
                render={(props) => <SuccessPage {...props} />}
              />
              <AuthenticatedRoute
                exact
                path="/player-registration"
                name="Player registration"
                render={(props) => <PlayerRegistration {...props} />}
              />
              <AuthenticatedRoute
                exact
                path="/player-registration-payment"
                name="Player payment"
                render={(props) => <PlayerPayment {...props} />}
              />
              <AuthenticatedRoute
                exact
                path="/bazaar-registration"
                name="Bazaar registration"
                render={(props) => <BazaarRegistration {...props} />}
              />
              <AuthenticatedRoute
                exact
                path="/bazaar-registration-payment"
                name="Bazaar payment"
                render={(props) => <BazaarPayment {...props} />}
              />
              <AuthenticatedRoute
                exact
                path="/bazaar-registration-success"
                name="Bazaar Success"
                render={(props) => <BazaarSuccessPage {...props} />}
              />
              <AuthenticatedRoute
                exact
                path="/conflict-submission-success"
                name="Conflict Submission Success"
                render={(props) => <ConflictSubmissionSuccess {...props} />}
              />
              <AuthenticatedRoute
                exact
                path="/conflict-submission"
                name="Conflict submission"
                render={(props) => <ConflictSubmission {...props} />}
              />
              <AuthenticatedRoute
                exact
                path="/conflict-submission-payment"
                name="conflict payment"
                render={(props) => <ConflictPayment {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />

              <AuthenticatedRoute
                adminOnly
                exact
                path="/dashboard"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />

              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-player-edit-2022"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-players-list-2022"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
               <AuthenticatedRoute
                adminOnly
                exact
                path="/a-send-games-push"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
               <AuthenticatedRoute
                adminOnly
                exact
                path="/a-send-push"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-team-list-2022"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-players-list-2021"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-team-list-2021"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-team-details-2022"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-scan-qr"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-upload-picture"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />

              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-vendor-list"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />

              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-vendor-details"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />

              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-confict-resolution"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />

              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-promo-code"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />

              <AuthenticatedRoute
                adminOnly
                exact
                path="/generate-promo-code"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />

              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-player-edit"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-players-list"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-team-list"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-team-details"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-matches-list"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-matches-details"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/create-new-match"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/import-matches"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/draw-teams"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <Route
                exact
                path="/match-gamesheet"
                name="Home"
                render={(props) => <MatchGameSheet {...props} />}
              />
              <Route
                exact
                path="/export-game-sheets"
                name="Home"
                render={(props) => <ExportGameSheets {...props} />}
              />
              <Route
                exact
                path="/view-schedule-standings"
                name="Home"
                render={(props) => <ScheduleStandingsViewer {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-academy-players-list-old"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
              <AuthenticatedRoute
                adminOnly
                exact
                path="/a-academy-players-list"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />

              <Route
                exact
                path="*"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
            </Switch>
          </React.Suspense>
        </Router>
      </AuthProvider>
    );
  }
}

export default App;

/*
 <Route path="/dashboard" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/business-info" name="Business Info" render={props => <TheLayout {...props} />} />
              <Route path="/business-addresses" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/add-roles" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/employees" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/manage-schedule" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/shift-trades" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/time-off-vacations" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/clock-in-out" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/shift-feedback" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/view-announcements" name="Users" render={props => <TheLayout {...props} />} />
              <Route path="/open-roles" name="Users" render={props => <TheLayout {...props} />} />
              <Route path="/messages" name="Users" render={props => <TheLayout {...props} />} />
*/
